import React from "react";
import errorEmoji from '../../assets/img/error.gif'
import logoImg from '../../assets/img/keepe1.png'
import { Link } from "react-router-dom";

export const ErrorPage = () => {
  return (
    <>
      <header class="header-wrap">
        <div class="container">
          <div class="d-flex align-items-center justify-content-between">
            <div class="main-logo">
              <Link to="/">
                <img src={logoImg} alt="keepe"/>
              </Link>
            </div>
          </div>
        </div>
      </header>
      <div className='loading-wrap'>
        <div className='error-block'>
          <div className="hi-title text-center text-white">404</div>
          <img src={errorEmoji} alt='loading' />
          <p className="text-white text-center">
            Sorry!  we could not find data for the link. <br />
            Please check your url and try again.
          </p>
          <div className="text-center mt-5">
            <Link to="/" class="theme-btn gradient-btn" type="button">Back To Home</Link>
          </div>
        </div>
      </div>
    </>
  )
};
export default ErrorPage;
