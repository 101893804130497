/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-multi-assign
const config = (module.exports = {});
config.env = 'development';

config.parse = {};
config.stripe = {};
config.google = {};
config.front = {};
config.typeFormUrl = {};
config.awsCaptcha = {};
config.log = {};
config.externallink = {};
config.log.dir = '/var/log/';
