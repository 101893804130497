const config = require('./config.global');

config.env = 'development';
config.parse.application_id = 'qxMVq9FTMwrCIHMT2Yd2vzVBTn0Yn5FLdyJLIb4u';
config.parse.javascript_key = 'yMygCzvAGs5osdAoTqpwzUqtGiFrteDiOc7iYJDt';
config.parse.api_url = 'https://api.keepe.com/api-dev';
config.externallink.bookr = 'http://localhost:3001/?headless=true&bodyless=true&utm_source=keepe.com&utm_medium=keepe.ai&utm_campaign=RepairScheduler';
config.typeFormUrl.startDiagnosis = 'https://pm-maintain.typeform.com/to/Hl4aBI';
config.awsCaptcha.enable = true;
config.awsCaptcha.api ='DvPjYvsQy9+JzIP+Vzq2zA8Yepf17muznhAByKYNM7+0YP5E3lr3oWTmzEmhVaYPxZxeSM4NR/6LgHj/93dM9gCJKQplZdxd/gnm46ftxONlv7U0n/LVBdOtKo4SFd8ycfNaKEYajrL+yTBLLnCIxQkHmATdj+lyfn02ME2lrNmklRUO1pG89KiSYUbLZIW4mGk2fL8tYAMw6r8t2zff6OZTczz7eOhE8GR80UVTHirRpa4riLBRuo+1ppQtM0ArTyrx5w3Z7qpae6h26WFkF/e51Q+zd9xHhKdc/SlBnUsYFLAgZGzUMLWOvBTJzxQ1KQMYT9fRXaFwKCq00c7CIvcuPVM6IcjEkUBJ9d5ClSZbPlTghP3ARd7DVf0GuAkoZk+eIqvEnZ+uQAg8vP8vJBifvtspXOF+aHHhB5B5fAQpKJ9VLWw9WtMwwEKIW6+v6KoNDR/l4UmzgsPMGYQdSNDdHxglpGiWdkQ9tZAMKw941vmjj1+n2XM7oCd79wOIflWrmewNI45c0zDuNX0yE9H9ZqwvHdNSvKzg5BTleMV+o8dS0JEbj+RW3w2CRSrI2B+l575nQJLt7gydL0z3rcWlaXUt4R9DLV7ZemoFS7aul62rWZDprLstqdli9aDOe/1jtr4/nrcfk1hgM+hHmS65pNqNFUJVCdgFyalRyPM=_0_1';
module.exports = config;