import React, { useEffect, useState } from "react";
import { loadImage } from "./Const";
import TypeFormComponent from "./TypeForm";
import config from "../config/index";
import { ModalView } from "./Modal";
import { captchaFun ,setDiagnosis } from "./CommonFun";
import { ModalViewCaptcha } from "./ModalCaptcha";

export const Header = () => {
  const pathname = window.location.pathname;
  const typeFormUrl = config.typeFormUrl.startDiagnosis;
  const captchaApi = config.awsCaptcha.api;
  const [menuOpen, setMenuOpen] = useState(false);
  const activeMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const [openTypeForm, setopenTypeForm] = useState(false);

  const openTypeFormHandle = (value) => {
    setopenTypeForm(value);
    setTimeout(() => {
      setopenTypeForm(false);
    }, 2000);
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenCaptcha, setIsOpenCaptcha] = React.useState(false);

  useEffect(() => { 
    if(pathname==='/book'){
      openModal();
    }
}, [pathname]);

useEffect(() => {
  const handlePopState = () => {
    if (modalIsOpen) {
      setIsOpen(false);
    }
  };
  window.addEventListener('popstate', handlePopState);

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };
}, [modalIsOpen]);

  function openModal() {
    setIsOpen(true);
    window.history.pushState({ modalIsOpen: true }, '');
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModalCaptcha() {
    setIsOpenCaptcha(true);
  }

  function closeModalCaptcha() {
    setIsOpenCaptcha(false);
  }
  const [loadingCaptcha, setLoadingCaptcha] = useState(false);

  const showMyCaptcha = () => {
    if (config.awsCaptcha.enable) {
      setLoadingCaptcha(true);
      var container = document.querySelector("#my-captcha-container-header");
      if (container instanceof Element) {
        container.style.height = "220px";
        container.style.width = "220px";
        if (typeof window.AwsWafCaptcha !== "undefined") {
          openModalCaptcha();
          setTimeout(() => setLoadingCaptcha(false), 1500);
          captchaFun(
            container,
            captchaApi,
            openModalCaptcha,
            captchaHandleSuccess,
            captchaHandleError
          );
        }
      } else {
        console.error("AwsWafCaptcha is not defined");
      }
    } else {
      openTypeFormHandle(true);
    }
  };
  function captchaHandleSuccess(wafToken) {
    console.log("wafToken->", wafToken);
    closeModalCaptcha();
    openTypeFormHandle(true);
  }

  function captchaHandleError(error) {
    console.log("error->", error);
    /* Do something with the error */
  }

  const typeFormClosed = () => {
    setDiagnosis();
  };
  return (
    <>
      <header className="header-wrap">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div className="main-logo">
              <img src={loadImage("keepe1.png")} alt="keepe" />
            </div>
            <div className="d-flex align-items-center mnw-flex">
              <div className={`main-menu ${menuOpen ? "active" : ""}`}>
                <div className="main-logo d-lg-none">
                  <img src={loadImage("keepe1.png")} alt="keepe" />
                </div>
                <ul className="d-flex align-items-center">
                  <li>
                    <a onClick={showMyCaptcha} href="#">
                      Start Diagnosis
                    </a>
                  </li>
                  <li>
                    <button onClick={openModal}>Book Technician</button>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.keepe.com/keeper?utm_source=home&utm_medium=keepe-header&utm_term=home&utm_content=home&utm_campaign=manager-landing-home"
                    >
                      Join our Pro Network
                    </a>
                  </li>

                  <li>
                    <a target="_blank"
                    rel="noopener noreferrer" href="https://blog.keepe.com/">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
              <div className="ms-3">
                {/* <button
                  className="theme-btn gradient-btn"
                  type="button"
                  onClick={() => openTypeFormHandle(!openTypeForm)}
                >
                  Start Diagnosis
                </button> */}
              <button
                className={`mobile-inner-header-icon ${
                  menuOpen
                    ? "mobile-inner-header-icon-click"
                    : "mobile-inner-header-icon-out"
                }`}
                type="button"
                onClick={activeMenu}
              >
                <span></span>
                <span></span>
                <span></span>
              </button>
              </div>
            </div>
          </div>
        </div>
      </header>
      {openTypeForm ? <TypeFormComponent typeFormUrl={typeFormUrl} typeFormClosed={typeFormClosed} /> : null}
      {modalIsOpen && (
        <ModalView
          modalIsOpen={modalIsOpen}
          closeModal={closeModal}
          src={config.externallink.bookr}
        />
      )}
      <ModalViewCaptcha
        modalIsOpen={modalIsOpenCaptcha}
        closeModal={closeModalCaptcha}
        id="my-captcha-container-header"
        loadingCaptcha={loadingCaptcha}
      />
    </>
  );
};
export default Header;
