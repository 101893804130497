import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/Home";
import ErrorPage from "./pages/ErrorPage/Error";

const SetRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/book" element={<HomePage />} />
      <Route path="/start-diagnosis" element={<HomePage />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
export default SetRoutes;
