import React, { forwardRef } from "react";
import { loadImage } from "./Const";

const Heighlight = forwardRef((props, ref) => {
  return (
    <section ref={ref} id="nextSection" className="overflow-hidden">
      <section className="info-wrap py-10">
        <div className="container">
          <div className="row align-items-center gx-5">
            <div
              className="col-md-6 wow animate__animated animate__zoomIn"
              data-wow-duration="1.5s"
              data-wow-delay="0s"
            >
              <div className="white-box p-0">
                <img className="w-100" src={loadImage("diagnose-free.png")} alt="icon" />
              </div>
            </div>
            <div
              className="col-md-6 hi-content"
            >
              <div className="hi-title mb-3">
                Diagnose for Free
              </div>
              <p>
                Keepe.ai self-diagnosis assistant is completely free for homeowners. You can diagnose the problem without providing a credit card. Answer a few simple questions to get a free diagnosis
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="info-wrap py-10">
        <div className="container">
          <div className="row align-items-center gx-5">
            <div
              className="col-md-6 wow animate__animated animate__zoomIn order-2"
              data-wow-duration="1.5s"
              data-wow-delay="0s"
            >
              <div className="white-box p-0">
                <img className="w-100" src={loadImage("next-steps-on-your-own.png")} alt="icon" />
              </div>
            </div>
            <div
              className="col-md-6 hi-content"
            >
              <div className="hi-title mb-3">
                Determine your next steps on your own
              </div>
              <p>
                Keepe.ai asks you for the minimum information needed to self-diagnose a home repair problem and gives you detailed information to help understand the underlying problem and provides next steps.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="info-wrap py-10">
        <div className="container">
          <div className="row align-items-center gx-5">
            <div
              className="col-md-6 wow animate__animated animate__zoomIn"
              data-wow-duration="1.5s"
              data-wow-delay="0s"
            >
              <div className="white-box p-0">
                <img className="w-100" src={loadImage("book-appointmen.jpg")} alt="icon" />
              </div>
            </div>
            <div
              className="col-md-6 hi-content"
            >
              <div className="hi-title mb-3">
                Can't DIY? We will DIFY (do-it-for-you)
              </div>
              <p>
                Keepe has a nationwide service provider network of thousands of vetted service providers. If you prefer that a technician come to your home and look at the problem, you can book a technician through Keepe.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="info-wrap py-10">
        <div className="container">
          <div className="row align-items-center gx-5">
            <div
              className="col-md-6 wow animate__animated animate__zoomIn order-2"
              data-wow-duration="1.5s"
              data-wow-delay="0s"
            >
              <div className="white-box p-0">
                <img className="w-100" src={loadImage("nationwide-coverage.png")} alt="icon" />
              </div>
            </div>
            <div
              className="col-md-6 hi-content"
            >
              <div className="hi-title mb-3">
                Nationwide Network of Technicians
              </div>
              <p>
                Over 100K work orders have been performed on Keepe’s Fulfillment Platform. Keepe’s nationwide network of vetted technicians are insured and background-checked 
              </p>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
});
export default Heighlight;
