import React, { useRef, useState, useEffect } from "react";
import BannerVideo from "../assets/video/banner.mp4";
import TypedAnimation from "./TypedAnimation";
import Heighlight from "./Heighlight";
import "@typeform/embed/build/css/popup.css";
import config from "../config/index";
import TypeFormComponent from "./TypeForm";
import "@typeform/embed/build/css/popup.css";
import { loadImage } from "./Const";
import { ModalViewCaptcha } from "./ModalCaptcha";
import { captchaFun ,setDiagnosis} from "./CommonFun";
import { initializeWow, cleanupWow } from './Const';

const Banner = () => {
  const pathname = window.location.pathname;
  const typeFormUrl = config.typeFormUrl.startDiagnosis;
  const captchaApi = config.awsCaptcha.api;
  const [openTypeForm, setopenTypeForm] = useState(false);
  const [modalIsOpenCaptcha, setIsOpenCaptcha] = React.useState(false);

  useEffect(() => {
    initializeWow();

    return () => {
      cleanupWow();
    };
  }, []);

  useEffect(() => { 
    if(pathname==='/start-diagnosis'){
      showMyCaptcha();
    }
}, [pathname]);

  function openModalCaptcha() {
    setIsOpenCaptcha(true);
  }

  function closeModalCaptcha() {
    setIsOpenCaptcha(false);
  }
  const openTypeFormHandle = (value) => {
    setopenTypeForm(value);
    setTimeout(() => {
      setopenTypeForm(false);
    }, 2000);
  };

  const nextSectionRef = useRef(null);

  const scrollToNextSection = () => {
    if (nextSectionRef.current) {
      nextSectionRef.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  };
  const [openReadMore, setOpenReadMore] = useState(false);
  const readMoreBtn = () => {
    setOpenReadMore(true);
  };
  const [loadingCaptcha, setLoadingCaptcha] = useState(false);
  const showMyCaptcha = () => {
    if (config.awsCaptcha.enable) {
      setLoadingCaptcha(true);
      var container = document.querySelector("#my-captcha-container-banner");
      if (container instanceof Element) {
        container.style.height = "220px";
        container.style.width = "220px";
        if (typeof window.AwsWafCaptcha !== "undefined") {
          openModalCaptcha();
          setTimeout(() => setLoadingCaptcha(false), 1500);
          captchaFun(
            container,
            captchaApi,
            openModalCaptcha,
            captchaHandleSuccess,
            captchaHandleError
          );
        }
      } else {
        console.error("AwsWafCaptcha is not defined");
      }
    } else {
      openTypeFormHandle(true);
    }
  };

  function captchaHandleSuccess(wafToken) {
    closeModalCaptcha();
    openTypeFormHandle(true);
  }

  function captchaHandleError(error) {
    console.log("error->", error);
    /* Do something with the error */
  }

  const typeFormClosed = () => {
    setDiagnosis();
  };
  
  return (
    <>
      {openTypeForm ? <TypeFormComponent typeFormUrl={typeFormUrl} typeFormClosed={typeFormClosed} /> : null}
      <section className="main-banner-wrap position-relative">
        <video id="background-video" loop autoPlay muted playsInline poster={loadImage("video_poster.png")}>
          <source src={BannerVideo} type="video/mp4" />
        </video>
        <div className="mb-overlay"></div>
        <div className="mb-pos position-absolute start-0 w-100">
          <div className="container">
            <div
              className="title-lg text-center mb-4 wow animate__animated animate__zoomIn"
              data-wow-duration="1s"
              data-wow-delay="0.5s"
            >
              Diagnose your <br/><TypedAnimation /> issue
            </div>
            <p
              className="text-white text-center mxw-650 wow animate__animated animate__zoomIn sub-heading"
              data-wow-duration="1s"
              data-wow-delay="1s"
            >
              AI-driven DIY Diagnosis Assistant for Home Repairs
              backed by the collective intelligence of hundreds of thousands of
              completed work-orders
            </p>
            <div
              className="mt-5 text-center wow animate__animated animate__zoomIn"
              data-wow-duration="1s"
              data-wow-delay="1.5s"
            >
              <button
                className="theme-btn gradient-btn"
                type="button"
                onClick={() => showMyCaptcha()}
              >
                Start Diagnosis
              </button>
            </div>
          </div>
        </div>
        <div className="info-msg">
          <p>The information provided on this website is for general informational purposes only and is not intended to be a substitute for professional advice... <button className="read-more-btn" type="button" onClick={readMoreBtn}>Read more</button></p>
        </div>
        <button
          type="button"
          onClick={scrollToNextSection}
          className="scroll-down arrow"
        >
          <span></span>
        </button>
        <div className={`read-more-modal ${openReadMore ? "open" : ""}`}>
          <button className="rm-close-icon" type="button" onClick={() => setOpenReadMore(false)}>
            x
          </button>
          <div className="readmore-modal-body">
            <p>The information provided on this website is for general informational purposes only and is not intended to be a substitute for professional advice. Always seek the advice of a qualified professional with any questions you may have regarding a medical condition, legal matter, or any other subject. Reliance on any information provided by this website is solely at your own risk.</p>
            <p>The website owner and contributors make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the information contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.</p>
            <p>In no event will the website owner or contributors be liable for any loss or damage including, without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from the use of this website.</p>
          </div>
        </div>
      </section>
      <Heighlight ref={nextSectionRef} />
      <ModalViewCaptcha
        modalIsOpen={modalIsOpenCaptcha}
        closeModal={closeModalCaptcha}
        id="my-captcha-container-banner"
        loadingCaptcha={loadingCaptcha}
      />
    </>
  );
};
export default Banner;
