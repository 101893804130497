import React, { useState ,useEffect } from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import Services from "../../components/Services";
import Testimonials from "../../components/Testimonials";
import OurClient from "../../components/OurClient";
import Footer from "../../components/Footer";
import { Loading } from "../../components/Loading";
const HomePage = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  if (loading) {
   return <Loading/>
  }
  return (
    <>
      <Header />
      <Banner />
      <Services />
      <Testimonials />
      <OurClient />
      <Footer />
    </>
  );
};
export default HomePage;
