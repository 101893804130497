import React from 'react';
export const ModalViewCaptcha = ({ modalIsOpen, closeModal, id,loadingCaptcha }) => {
  return (
    <>
      <div className={`custom-modal captcha-modal modal fade ${modalIsOpen ? "in show d-block" : ""}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
          <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeModal}
              >
                &times;
              </button>
            <div className="modal-body ">
              <div className="aws-captcha-body">
                {loadingCaptcha &&
                  <div className='spinner-pos'>
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                    <div className='title-md text-center mt-2 title-captcha'>Let's confirm you are human</div>
                  </div>}
                  <div className={`${loadingCaptcha && "opacity-0"}`}>
                  <div id={id}></div>
                 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal-backdrop fade ${modalIsOpen ? "show d-block" : "d-none"}`}></div>
    </>
  );
};