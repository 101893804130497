import React from 'react'
import loadingIcon from '../assets/img/loderKeepe.gif'

export const Loading = () => {
  return (
    <div className='loading-wrap'>
      <div className='loading-icon'>
        <img src={loadingIcon} alt='loading' width="350"/>
      </div>
    </div>
  )
};
