import React from "react";
import { loadImage } from "./Const";

const Services = () => {
  return (
    <section className="overflow-hidden">
      <section className="services-wrap py-10">
        <div className="container">
          <div
            className="title-lg text-center text-black fw-700 mb-3 wow animate__animated animate__zoomIn"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            Partner with us
          </div>
          <div
            className="title-md fw-500 mxw-650 text-center wow animate__animated animate__zoomIn"
            data-wow-duration="1s"
            data-wow-delay="1s"
          >
            Keepe makes this technology available to license to our warranty, insurance, multi-family, property management and other real estate companies for easy deployment on their websites and other interfaces for the use of their customer base
          </div>
          <div className="d-flex align-items-cnter flex-wrap pwu-block justify-content-center">
            <div className="pwu-col mt-5 wow animate__animated animate__zoomIn"
            data-wow-duration="1.5s"
            data-wow-delay="1s">
              <div className="pwu-box">
                <div className="pwu-icon pwu-dark-blue mb-4">
                  <img className="w-100" src={loadImage("warranty.jpeg")} alt="icon" />
                </div>
                <div className="title-md text-center">Warranty</div>
              </div>
            </div>
            <div className="pwu-col mt-5 wow animate__animated animate__zoomIn"
            data-wow-duration="1.5s"
            data-wow-delay="1.3s">
              <div className="pwu-box">
                <div className="pwu-icon pwu-dark-blue mb-4">
                  <img className="w-100" src={loadImage("home-insurance.png")} alt="icon" />
                </div>
                <div className="title-md text-center">Home Insurance</div>
              </div>
            </div>
            <div className="pwu-col mt-5 wow animate__animated animate__zoomIn"
            data-wow-duration="1.5s"
            data-wow-delay="1.6s">
              <div className="pwu-box">
                <div className="pwu-icon pwu-dark-blue mb-4">
                  <img className="w-100" src={loadImage("multifamily-real-estate-2.webp")} alt="icon" />
                </div>
                <div className="title-md text-center">Multi-Family</div>
              </div>
            </div>
            <div className="pwu-col mt-5 wow animate__animated animate__zoomIn"
            data-wow-duration="1.5s"
            data-wow-delay="1.9s">
              <div className="pwu-box">
                <div className="pwu-icon pwu-dark-blue mb-4">
                  <img className="w-100" src={loadImage("PropertyManagement.jpeg")} alt="icon" />
                </div>
                <div className="title-md text-center">Property Management</div>
              </div>
            </div>
            <div className="pwu-col mt-5 wow animate__animated animate__zoomIn"
            data-wow-duration="1.5s"
            data-wow-delay="2.1s">
              <div className="pwu-box">
                <div className="pwu-icon pwu-dark-blue mb-4">
                  <img className="w-100" src={loadImage("REITs.jpeg")} alt="icon" />
                </div>
                <div className="title-md text-center">REITs</div>
              </div>
            </div>
            <div className="pwu-col mt-5 wow animate__animated animate__zoomIn"
              data-wow-duration="1.5s"
              data-wow-delay="2.5s">
              <div className="pwu-box">
                <div className="pwu-icon pwu-dark-blue mb-4">
                  <img className="w-100" src={loadImage("Utilities.jpg")} alt="icon" />
                </div>
                <div className="title-md text-center">Utilities</div>
              </div>
            </div>
            <div className="pwu-col mt-5 wow animate__animated animate__zoomIn"
              data-wow-duration="2s"
              data-wow-delay="2.8s">
              <div className="pwu-box">
                <div className="pwu-icon pwu-dark-blue mb-4">
                  <img className="w-100" src={loadImage("EV-Charging.jpg")} alt="icon" />
                </div>
                <div className="title-md text-center">EV Charging</div>
              </div>
            </div>
            <div className="pwu-col mt-5 wow animate__animated animate__zoomIn"
              data-wow-duration="2s"
              data-wow-delay="3s">
              <div className="pwu-box">
                <div className="pwu-icon pwu-dark-blue mb-4">
                  <img className="w-100" src={loadImage("Manufacturers.png")} alt="icon" />
                </div>
                <div className="title-md text-center">Manufacturers</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};
export default Services;
