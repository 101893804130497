import Parse from 'parse';
 export const captchaFun = ( container, captchaApi, openModalCaptcha, captchaHandleSuccess, captchaHandleError) => {
    if (typeof window.AwsWafCaptcha !== "undefined") {
      openModalCaptcha();
      window.AwsWafCaptcha.renderCaptcha(container, {
        apiKey: captchaApi,
        onSuccess: captchaHandleSuccess,
        onError: captchaHandleError,
        dynamicWidth :true,
        skipTitle: true,
      });
    } else {
      console.error("AwsWafCaptcha is not defined");
    }
  };

  export const setDiagnosis = () => {
    var urlInclude = "jobid=";
    var urlFound;
    var iframes = document.querySelectorAll("iframe");
    if (iframes.length > 0) {
      iframes.forEach((iframe) => {
        var src = iframe.getAttribute("src");
        if (src && src.includes(urlInclude)) {
          urlFound = src;
        }
      });
    }
    if(urlFound && urlFound.length > 0) {
      const jobsApiFunction = "setDiagnosis";
      Parse.Cloud.run(jobsApiFunction, {
        url: urlFound
      }).then((result) => {
        //do nothing
      }).catch((err) => {
        console.log("error ", err);
      });
    }
  };