import { TypeAnimation } from "react-type-animation";
const TypedAnimation = () => {
  return (
    <TypeAnimation
      sequence={[
        "Home Repair",
        2000,
        "Appliance",
        1500,
        "Plumbing",
        1500,
        "Heating & Cooling",
        1500,
        "Handyman",
        1500,
        "Electrical",
        1500,
        "Roof",
        1500,
        "Lawn",
        1500,
        "Fireplace",
        1500,
        "Hot Tub",
        1500,
        "Garage Door",
        1500,
      ]}
      wrapper="span"
      speed={10}
      repeat={Infinity}
      cursor={false}
      nonce="25a6c7f29c"
      className="heading-typed-flip"
    />
  );
};
export default TypedAnimation;
