import React from "react";

export const ModalView = ({ modalIsOpen, closeModal, src }) => {

  return (
    <>
      <div className={`custom-modal book-technician-modal modal fade ${modalIsOpen ? "in show d-block" : ""}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">       
          <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={closeModal}
              >
                &times;
              </button>
            <div className="modal-body">      
              <iframe
                title="External Content"
                src={src}
                width="100%"
                height='585px'
              />
            </div>
          </div>
        </div>
      </div>
      <div className={`modal-backdrop fade ${modalIsOpen ? "show d-block" : "d-none"}`}></div>
    </>
  );
};
