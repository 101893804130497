import { useEffect}  from "react";
import { createPopup } from "@typeform/embed";
import "@typeform/embed/build/css/popup.css";

const TypeFormComponent = ({ typeFormUrl,typeFormClosed }) => {
  const URL = `${typeFormUrl}?jobid=none&utm_source=KeepeAI`;
  const encodedUrl = encodeURI(URL);

  useEffect(() => {
      const { toggle } = createPopup(encodedUrl, {
        autoClose: 3000,
        position: "right",
        width: "80%",
        onClose :function () {
          typeFormClosed();
        },
      });
      toggle();
  }, [typeFormUrl]);

  return null;
};

export default TypeFormComponent;
